"use client";

import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getDynamicStoreDetails } from "../../../components/auth/firestore/dynamicStoreDetails";
import Offers from "./offers";
import StoreWalletInfoCard from "../storeInfo";
import { QrCode } from "../../../components/qrCode/qrCreator";
import { QrButton, WalletButton } from "../../../components/lib/fabButtons";

interface StoreDetails {
  storeName: string;
  titleImage?: string;
  city: string;
  province: string;
  storeNumber: string;
  socialURL?: string;
  storeID?: string;
  offers?: Array<{ title: string; description: string; offerImage: string }>;
}

interface DynamicWalletStoreProps {
  storeURL: string;
}

const DynamicWalletStore: React.FC<DynamicWalletStoreProps> = ({
  storeURL,
}) => {
  const [storeDetails, setStoreDetails] = useState<StoreDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [qrDialogOpen, setQrDialogOpen] = useState(false);

  useEffect(() => {
    if (storeURL) {
      getDynamicStoreDetails(storeURL)
        .then((details) => {
          setStoreDetails(details || null);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching store details:", error);
          setLoading(false);
        });
    }
  }, [storeURL]);

  const closeQrDialog = () => setQrDialogOpen(false);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!storeDetails) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Card sx={{ padding: 2 }}>
          <Typography variant="h6">Store not found!</Typography>
        </Card>
      </Box>
    );
  }

  return (
    <HelmetProvider>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          minHeight: "90vh",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{storeDetails.storeName}</title>
          <meta
            name="keywords"
            content={`stomp, stomp card, loyalty card, store, rewards, stamp card, ${storeDetails.storeName}`}
          />
        </Helmet>

        {/* Store Info Card */}
        <StoreWalletInfoCard
          titleImage={storeDetails.titleImage}
          storeName={storeDetails.storeName}
          socialURL={storeDetails.socialURL}
          city={storeDetails.city}
          province={storeDetails.province}
          storeNumber={storeDetails.storeNumber}
        />

        {/* Offers Section */}
        <Offers storeOffers={storeDetails.offers} />
      </Box>

      {/* Action Buttons */}
      <Box>
        <Box
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(5),
            right: (theme) => theme.spacing(3),
          }}
        >
          <QrButton />
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(5),
            left: (theme) => theme.spacing(3),
          }}
        >
          <WalletButton />
        </Box>
      </Box>

      {/* QR Code Dialog */}
      <Dialog open={qrDialogOpen} onClose={closeQrDialog}>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QrCode />
        </DialogContent>
      </Dialog>
    </HelmetProvider>
  );
};

export default DynamicWalletStore;
