import React, { useContext, useState, useEffect } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
  Theme,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { getFirestoreCards } from "../auth/firestore/getFirestoreCards";
import { LogoutDialog, RefreshDialog } from "../reusables/dialogs";
import { authClient } from "../lib/auth/client";
import stompBack2 from "../../assets/stomp-back2.svg";
import { UserContext } from "../../contexts/user-context";
import StompLogo from "../../stompCardsLogo.svg";
import { Link } from "react-router-dom";

const ThinHeader: React.FC = () => {
  const context = useContext(UserContext);
  const user = context?.user;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLogoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [isRefreshDialogOpen, setRefreshDialogOpen] = useState(false);
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  useEffect(() => {
    document.body.style.backgroundImage = `url(${stompBack2})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "repeat-y";
    document.body.style.backgroundPosition = "center";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const userID = user?.uid || "";

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleRefresh = async () => {
    if (userID) {
      try {
        setRefreshDialogOpen(false);
        await getFirestoreCards(userID, true);
        window.location.reload();
      } catch (error) {
        console.error("Error refreshing cards:", error);
      }
    }
  };

  const handleLogout = () => setLogoutDialogOpen(true);

  const confirmLogout = () => {
    handleMenuClose();
    localStorage.clear();
    authClient.signOut();
    window.location.reload();
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Stomp Cards: The Digital Wallet to Stomp Loyalty</title>
        <meta
          name="description"
          content="Stomp Cards is your app-less wallet to access all your Stomp Loyalty stamp cards. If you prefer a traditional app layout, please install our website under settings with the add to Home Screen Button!"
        />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          padding: "10px 10px",
          backgroundColor: "rgba(33, 33, 33, 1)",
          zIndex: 100,
          boxShadow: "0 0 10px rgba(100, 100, 100, 0.2)",
          height: "35px",
        }}
      >
        <Box sx={{ mb: "-5.5px" }}>
          <Link to="/wallet" style={{ textDecoration: "none" }}>
            {isLargeScreen ? (
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                }}
              >
                Stomp Cards
              </Typography>
            ) : (
              <Box
                component="img"
                src={StompLogo}
                alt="Stomp Cards: Wallet"
                sx={{ height: 40, borderRadius: "50%" }}
              />
            )}
          </Link>
        </Box>

        {userID && (
          <>
            <IconButton
              onClick={handleMenuOpen}
              color="inherit"
              aria-label="settings"
              sx={{
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                },
              }}
            >
              <SettingsIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ marginTop: "2px" }}
            >
              <MenuItem>
                <Button
                  sx={{ width: "100px" }}
                  variant="contained"
                  color="success"
                  onClick={() => setRefreshDialogOpen(true)}
                >
                  Refresh
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  sx={{ width: "100px" }}
                  variant="contained"
                  color="error"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </MenuItem>
            </Menu>
          </>
        )}

        <LogoutDialog
          open={isLogoutDialogOpen}
          onCancel={() => setLogoutDialogOpen(false)}
          onConfirm={confirmLogout}
        />
        <RefreshDialog
          open={isRefreshDialogOpen}
          onCancel={() => setRefreshDialogOpen(false)}
          onConfirm={handleRefresh}
        />
      </Box>
    </HelmetProvider>
  );
};

export default ThinHeader;
