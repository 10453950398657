import React from "react";
import { Container, Box } from "@mui/material";
import SignInForm from "../components/auth/mainAuth/SignInForm";

const SignInPage: React.FC = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "100%" }}>
        {" "}
        <SignInForm />
      </Box>
    </Container>
  );
};

export default SignInPage;
