import React from "react";
import { Typography, Link, Box, Divider } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" component="h1" fontWeight="bold">
          Terms and Conditions
        </Typography>
        <Divider sx={{ mt: 1 }} />
        <Typography variant="body1" sx={{ mt: 1 }}>
          Defined terms and conditions for Stomp Loyalty stamp card suite.
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Last updated: 06 August 2024
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          {" "}
          Stomp Loyalty is owned and operated by Stomp Loyalty Inc.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          THIS AGREEMENT BECOMES EFFECTIVE WHEN YOU ACCEPT IT BY COMPLETING THE
          STOMP LOYALTY ACCOUNT REGISTRATION FORM. IF YOU ARE ACCEPTING THIS
          AGREEMENT ON BEHALF OF A COMPANY OR ANOTHER LEGAL ENTITY, YOU CONFIRM
          THAT YOU HAVE THE AUTHORITY TO BIND THAT ENTITY AND ITS AFFILIATES TO
          THESE TERMS AND CONDITIONS. IN THAT CASE, THE TERMS “YOU,” “YOUR,” OR
          “CUSTOMER” WILL REFER TO THAT ENTITY AND ITS AFFILIATES. IF YOU DO NOT
          HAVE THIS AUTHORITY OR DO NOT AGREE WITH THE TERMS AND CONDITIONS, YOU
          SHOULD NOT ACCEPT THIS AGREEMENT AND SHOULD REFRAIN FROM USING THE
          SERVICE.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          This agreement supersedes any previous agreements between the parties
          related to the subject matter outlined in this document.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          It becomes effective on the date you accept it, either by checking a
          box to indicate your acceptance or by signing a contract that refers
          to this agreement.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          1. DEFINITIONS
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 1 }}>
          <strong>"Acceptable Use Policy"</strong> refers to Stomp Loyalty's
          guidelines for proper use of the Service. The latest version of this
          policy can be found here:
          <Link href="https://www.stomployalty.com" sx={{ fontWeight: 400 }}>
            Acceptable Use Policy
          </Link>
          .
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 1 }}>
          <strong>"Account User"</strong> designates an individual authorized by
          the Customer to access and use the Service, to whom Stomp Loyalty has
          provided a login ID and password. Account Users may include the
          Customer's employees, consultants, contractors, and agents, but not
          Stomp Loyalty’s competitors.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Affiliate"</strong> means any entity that directly or
          indirectly controls, is controlled by, or is under common control with
          the subject entity. "Control," for this definition, means owning or
          controlling more than 50% of the voting interests of the subject
          entity, either directly or indirectly.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Agreement"</strong> refers to these Stomp Loyalty Terms and
          Conditions for the Subscription Service and all terms included in the
          webpage links referenced in this document.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Applicable Laws"</strong> encompass laws, statutes,
          regulations, or directives applicable to the provision or use of the
          Service, including, but not limited to, data privacy and digital
          messaging laws.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Customer"</strong> is the company or legal entity on behalf
          of which you are accepting this Agreement.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Customer Data"</strong> refers to electronic data and content
          submitted or processed by the Service on behalf of the Customer.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"DPA"</strong> stands for the Data Processing Addendum that
          applies to personal data processing under the EU General Data
          Protection Regulation, the California Consumer Privacy Act, or similar
          data privacy laws in connection with the Customer's use of the
          Service. The latest version of the DPA can be found here:
          <Link href="https://www.stomployalty.com" sx={{ fontWeight: 400 }}>
            Data Processing Addendum
          </Link>
          .
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Documentation"</strong> means the online resources for the
          Service, updated periodically, and accessible via
          <Link href="https://www.stomployalty.com" sx={{ fontWeight: 400 }}>
            Documentation
          </Link>
          .
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"End User"</strong> refers to any individual using one or more
          of the Digital Stamp Cards or Passes.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Malicious Code"</strong> means any code, files, scripts,
          agents, or programs intended to cause harm, including viruses, worms,
          time bombs, and Trojan horses.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Notification"</strong> refers to any communication with End
          Users made by or on behalf of the Customer via the Service related to
          a Pass.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Pass(es)"</strong> denotes one or more digital passes (e.g.,
          stamp cards) that the Customer owns or controls and makes available to
          End Users.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Stomp Loyalty"</strong> means Stomp Loyalty, Inc., a
          Vancouver corporation with its principal place of business at 15027 82
          Ave, Surrey, BC, V3S7V6, Canada.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Stomp Loyalty Library"</strong> refers to the most current
          version of Stomp Loyalty's proprietary code and binary library
          available for use with the Service, including Stomp Loyalty SDKs and
          APIs.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Prohibited Data"</strong> includes: (a) government-issued IDs
          like passport numbers, taxpayer numbers, and driver's license numbers;
          (b) individual medical or health information (e.g., protected health
          information under HIPAA); (c) individual financial information or
          account numbers (e.g., credit or debit card numbers, bank account
          numbers); (d) security codes or passwords (except for Customer’s
          account passwords on the Service); or (e) “special categories of
          personal data” under the EU General Data Protection Regulation or
          similar information under other applicable laws.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Registration Form"</strong> refers to the webpage where you
          signed up for an account on the Service (
          <Link
            href="https://www.stomployalty.com/auth/sign-in"
            sx={{ fontWeight: 400 }}
          >
            https://www.stomployalty.com/auth/sign-in
          </Link>
          ).
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Service"</strong> refers to the Stomp Loyalty software as a
          service product specified. It includes the Stomp Loyalty web
          applications and the Stomp Loyalty Library but excludes Third Party
          Applications.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"Third Party Applications"</strong> are third-party web-based
          or offline software applications, operating systems (such as iOS or
          Android), platforms, networks, certificates, or devices that interact
          with the Service. Note that Third Party Applications do not include
          subcontractors or OEM providers of Stomp Loyalty.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>"You" or "Your"</strong> refers to the company or legal entity
          on behalf of which you are accepting this Agreement.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          {" "}
          2. USE OF SERVICE
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>2.1 Use of Service.</strong> To use the Service and establish
          your account, you must complete the registration process by providing
          Stomp Loyalty with accurate, current, and complete information as
          specified in the Registration Form. Subject to your compliance with
          this Agreement and maintaining an active subscription, Stomp Loyalty
          grants you a non-exclusive, non-transferable, and non-sublicenseable
          license to access and use the Service for which you are subscribed,
          strictly for your business’s regular operations and only in locations
          where the Service’s use is legally permitted. Your access to and use
          of the Service are further governed by the Documentation, which you
          are responsible for reviewing and understanding.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>2.2 Customer Responsibilities.</strong> You are responsible
          for: (a) managing your and your Account Users' use of the Service and
          ensuring compliance with this Agreement; (b) maintaining your
          account’s security credentials and informing Stomp Loyalty promptly of
          any security breaches or unauthorized access; (c) responding to
          reasonable requests from Stomp Loyalty regarding account configuration
          to improve Service performance; (d) ensuring the accuracy of Customer
          Data and having the proper permissions for its legal processing and
          use, including data from Third Party Applications linked to the
          Service, Aggregated Service Data, or Aggregate Usage Data; (e)
          maintaining legally sufficient privacy policies and notices for each
          Pass connected to the Service; (f) providing necessary notifications,
          responding to individual rights requests, and obtaining all required
          permissions and consents for collecting, processing, storing, using,
          transmitting, and disclosing Customer Data as described in this
          Agreement and the Documentation. If relevant, the terms of the DPA are
          incorporated by reference, and you must fulfill your obligations as a
          data controller under the DPA.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>2.3 Usage Restrictions.</strong> You must not (a) make the
          Service available to or use it for anyone other than yourself; (b)
          sell, resell, license, sublicense, distribute, rent, or lease the
          Service, or include it in any service bureau or outsourcing
          arrangement; (c) use the Service in a manner that violates Applicable
          Law or the terms of any Third Party Application; (d) use the Service
          to store or transmit Malicious Code; (e) interfere with or disrupt the
          Service’s integrity, performance, or the data contained within; (f)
          attempt unauthorized access to the Service or its related systems or
          networks; (g) allow access to or use of the Service in a way that
          bypasses contractual usage limits or security measures; (h) copy any
          part of the Service, including its features or user interface; (i)
          access the Service to develop or enhance a competing product or
          service; (j) use the Service for automated processing to make
          decisions about individuals that have significant legal effects, such
          as determining credit, employment, or insurance eligibility; or (k)
          handle any Prohibited Data using the Service. Stomp Loyalty reserves
          the right to refuse to transmit or remove any Customer Data that
          breaches this Agreement, the Acceptable Use Policy, or Applicable
          Laws, at its discretion. However, Stomp Loyalty is not obligated to
          review Customer Data or Notification content. If your or any Account
          User’s use of the Service threatens its security, stability, or
          availability, Stomp Loyalty may suspend or terminate the Service
          immediately. Stomp Loyalty will not be liable for any such suspension
          or termination made in good faith.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>2.4 Third Party Applications.</strong> The Service may allow
          you to integrate Third Party Applications. By linking such
          applications, you: (i) authorize Stomp Loyalty to access, receive,
          and, where necessary, store data from the Third Party Application via
          the Service (all such data being Customer Data), and (ii) permit Stomp
          Loyalty to grant the Third Party Application provider access to
          Customer Data via the Service, solely as needed for the integration of
          that Third Party Application. Stomp Loyalty is not responsible for any
          use, transmission, disclosure, loss, modification, or deletion of
          Customer Data or other content related to Third Party Applications
          linked to the Service. You are solely responsible for obtaining and
          securing all necessary rights and permissions from the Third Party
          Application provider. Stomp Loyalty is not liable for issues arising
          from Third Party Applications or their providers. Your access to and
          use of Third Party Applications (including their integration with the
          Service) are governed by the terms and conditions of the Third Party
          Application provider or any separate agreements you make with them,
          and Stomp Loyalty disclaims any responsibility in this regard.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          {" "}
          3. TERM AND TERMINATION
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>3.1 Term.</strong> Stomp Loyalty will grant access to the
          Service for the duration specified in the Registration Form (the
          “Term”). The Term will automatically renew unless either party
          notifies the other party of its intention not to renew. After
          termination, you will lose access to both the Service and Customer
          Data.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>3.2 Termination.</strong> Either party may terminate this
          Agreement for cause: (a) with three (3) days’ written notice of a
          material breach if the breach remains unresolved after this period,
          (b) immediately if your use of the Service exceeds the applicable
          limitations, or (c) immediately if the other party becomes subject to
          bankruptcy or insolvency proceedings, receivership, liquidation, or
          any assignment for the benefit of creditors. Additionally, Stomp
          Loyalty may suspend or terminate your access to the Service
          immediately for (i) non-payment of fees when due, (ii) any actual or
          suspected violation of the Acceptable Use Policy or obligations in
          Sections 2 or 5.4, or (iii) if your use of the Service, or any
          activity related to your account, disrupts or impairs the Service.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>3.3 Effect of Termination.</strong> Upon termination or
          expiration of this Agreement, you must stop all access to and use of
          the Service. If the Agreement is terminated (or if your access is
          suspended or terminated): (i) you will not be entitled to any refunds,
          and (ii) any outstanding balance for Services provided up to the
          termination date, along with any other unpaid amounts for the
          remaining subscription term, will become immediately due and payable
          in full (unless termination is due to a service malfunction or a
          breach by Stomp Loyalty). The following sections will remain in effect
          after expiration or termination: 2.2, 3.3, and Sections 5 through 10.
        </Typography>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          {" "}
          4. FEES AND PAYMENT
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>4.1 Fees.</strong> During the registration process and before
          any fees are incurred, you must provide Stomp Loyalty with valid and
          current credit card information and authorize Stomp Loyalty to charge
          this credit card for any applicable fees. You are responsible for
          supplying accurate billing and contact information and for updating
          Stomp Loyalty with any changes to this information. Unless specified
          otherwise, all payments will be made in U.S. dollars.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>4.2 Taxes.</strong> The Service fees do not cover any taxes,
          levies, duties, or similar governmental charges, including but not
          limited to value-added, sales, use, or withholding taxes imposed by
          any jurisdiction (collectively referred to as “Taxes”). You are
          responsible for paying all Taxes related to your subscription to the
          Service. If Stomp Loyalty is required by law to pay or collect any
          Taxes for which you are responsible, Stomp Loyalty will invoice you
          for these amounts unless you provide a valid tax exemption certificate
          from the appropriate taxing authority. Stomp Loyalty is responsible
          for taxes related to its own income, property, and employees.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>4.3 Late Payments.</strong> Failure to pay any amounts due on
          time will be considered a material breach of this Agreement, and Stomp
          Loyalty may suspend or terminate your access to the Service as
          outlined in Section 3.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          {" "}
          5. PROPRIETARY RIGHTS AND LICENSES; CUSTOMER DATA
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>5.1 Ownership of the Service.</strong> Stomp Loyalty (and its
          licensors, where applicable) retains all ownership rights, title, and
          interest in the Service, including all related intellectual property
          rights such as patents, patent applications, copyrights, trademarks,
          trade names, domain names, mask work rights, know-how, and trade
          secrets. This includes all inventions and derivative works related to
          the Service. You receive no rights other than those explicitly granted
          in this Agreement, and all rights not expressly granted are reserved
          by Stomp Loyalty (and its licensors).
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>5.2 License to Use the Stomp Loyalty Library.</strong> Stomp
          Loyalty grants you a worldwide, limited-term license to use the Stomp
          Loyalty Library exclusively in connection with the Service and in
          compliance with this Agreement and the Documentation throughout the
          applicable Term.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>5.3 Ownership of Customer Data.</strong> You exclusively own
          all rights, title, and interest in your Customer Data and Passes. You
          grant Stomp Loyalty a non-exclusive, royalty-free, non-transferable
          (except as permitted under this Agreement), worldwide license during
          the Term to access, copy, modify, display, store, perform, and
          distribute Customer Data for the purpose of providing the Service,
          including integration with any linked Third Party Applications. You
          affirm that you have all necessary rights and permissions to grant
          Stomp Loyalty these rights, including any permissions required from
          Third Party Application providers.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>5.4 Aggregated Data.</strong> Stomp Loyalty aggregates (1)
          anonymized End User Data from your account to enable Service
          functionalities (“Aggregated Service Data”); and (2) anonymized
          quantitative data from your Service usage combined with de-identified
          data from other Stomp Loyalty customers (“Aggregated Usage Data”). You
          agree that Stomp Loyalty may use Aggregated Service Data to provide
          Service functionalities. Stomp Loyalty will not merge Aggregated
          Service Data from your account with data from other Stomp Loyalty
          customers. Additionally, you consent to Stomp Loyalty using Aggregated
          Usage Data to analyze, enhance, develop, support, and operate the
          Service, and to prepare general benchmarking and industry reports for
          its customers, which may be published on Stomp Loyalty’s blogs and
          websites. This section does not permit Stomp Loyalty to use Aggregated
          Service Data or Aggregated Usage Data to identify any individual, End
          User, or Customer, nor does it allow Stomp Loyalty to sell or disclose
          raw data or personal data from Aggregated Service Data or Aggregated
          Usage Data to any third party.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>5.5 Feedback.</strong> Any feedback you provide, whether
          suggestions, enhancement requests, recommendations, or corrections
          (collectively, “Feedback”), is given voluntarily. By providing
          Feedback to Stomp Loyalty, you grant Stomp Loyalty and its Affiliates
          a worldwide, perpetual, irrevocable, transferable, sublicensable,
          royalty-free license to use and integrate this Feedback into the
          Service.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          {" "}
          6. DISCLAIMER OF WARRANTIES
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          STOMP LOYALTY AND ITS LICENSORS MAKE NO WARRANTIES OF ANY KIND,
          WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. STOMP LOYALTY AND
          ITS LICENSORS EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING BUT
          NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, OR NON-INFRINGEMENT, TO THE FULLEST EXTENT
          PERMITTED BY APPLICABLE LAW. THE SERVICE IS PROVIDED "AS IS," WITHOUT
          ANY WARRANTY WHATSOEVER. STOMP LOYALTY DISCLAIMS ALL LIABILITY AND
          INDEMNIFICATION OBLIGATIONS FOR ANY DAMAGE OR INJURY CAUSED BY
          THIRD-PARTY HOSTING PROVIDERS OR APPLICATIONS.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          {" "}
          7. LIMITATION OF LIABILITY
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          STOMP LOYALTY'S AND ITS LICENSORS' TOTAL LIABILITY ARISING FROM OR
          RELATED TO THIS AGREEMENT OR THE SERVICE IS LIMITED TO $1,000. THIS
          LIMITATION APPLIES REGARDLESS OF WHETHER THE CLAIM IS BASED ON
          CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY. STOMP
          LOYALTY AND ITS LICENSORS WILL NOT BE LIABLE TO YOU, ANY ACCOUNT USER,
          OR ANY THIRD PARTY FOR ANY LOST PROFITS OR REVENUE, OR FOR INDIRECT,
          SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER, OR PUNITIVE DAMAGES,
          WHETHER IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY,
          EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION OF
          LIABILITY WILL NOT APPLY WHERE PROHIBITED BY LAW IN THE RELEVANT STATE
          OR JURISDICTION.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          YOU ACKNOWLEDGE AND AGREE THAT THE PRIMARY INTENT OF THIS SECTION 7 IS
          TO ALLOCATE RISKS BETWEEN THE PARTIES AND LIMIT POTENTIAL LIABILITY,
          GIVEN THAT THE SERVICE IS PROVIDED EITHER FREE OF CHARGE OR AT A
          LIMITED COST. IF STOMP LOYALTY WERE TO ASSUME ANY ADDITIONAL LIABILITY
          BEYOND WHAT IS STATED HEREIN, STOMP LOYALTY WOULD HAVE CHARGED
          SIGNIFICANTLY HIGHER FEES FOR USE OF THE SERVICE. STOMP LOYALTY HAS
          RELIED ON THESE LIMITATIONS IN DECIDING TO PROVIDE YOU WITH ACCESS TO
          AND USE OF THE SERVICE UNDER THIS AGREEMENT.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          {" "}
          8. INDEMNIFICATION
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>8.1 Stomp Loyalty Indemnification.</strong> Stomp Loyalty
          agrees to defend, indemnify, and hold you harmless from any
          third-party claim, demand, suit, or proceeding (each, a "Claim")
          brought against you by a third party, including any direct damages,
          liabilities, losses, settlements, judgments, costs, and expenses
          (including reasonable attorney's fees and costs) (collectively,
          "Losses") related to allegations that the Service provided by Stomp
          Loyalty under this Agreement infringes or misappropriates a third
          party's copyrights, trademarks, or trade secrets under applicable
          laws. If Stomp Loyalty becomes aware of an infringement or
          misappropriation claim concerning the Service, it may, at its
          discretion and at no cost to you, (i) modify the Service to avoid
          infringement or misappropriation, (ii) obtain a license for your
          continued use of the Service, or (iii) terminate your subscriptions
          for the affected Service with 30 days' written notice and refund any
          prepaid fees for the remaining term. This indemnification does not
          cover claims arising from Customer Data, Third Party Applications,
          Notifications, breaches of this Agreement by you or any Account User,
          modifications to the Service by or for you, or failure to implement
          modifications, upgrades, replacements, or enhancements provided by
          Stomp Loyalty at no additional cost.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>8.2 Your Indemnification.</strong> You agree to defend,
          indemnify, and hold Stomp Loyalty, its Affiliates, and licensors
          ("Stomp Loyalty Indemnified Parties") harmless from any Claim and
          related Losses arising from (i) your or any Account User's use of the
          Service, (ii) Notifications, (iii) your Data, (iv) your or any Account
          User's infringement or misappropriation of intellectual property
          rights, breach of obligations to third parties, or violation of
          Applicable Laws, or (v) your or any Account User's breach of Section
          2.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>8.3 Indemnification Process.</strong> The indemnifying party's
          obligations are subject to the indemnified party (i) providing prompt
          written notice of the claim (failure to do so will not relieve the
          indemnifying party of its obligations except if it materially impairs
          the indemnifying party's ability to defend), (ii) allowing the
          indemnifying party full control of the defense and settlement
          (although the indemnified party may participate with its own counsel
          at its own expense), (iii) reasonably cooperating with the
          indemnifying party, at the indemnifying party's expense for
          out-of-pocket costs, in the defense and settlement of the claim, and
          (iv) not admitting any fault or liability of the indemnifying party or
          itself.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>8.4 Exclusive Remedy.</strong> This Section 8 outlines the
          indemnifying party's sole liability to, and the indemnified party's
          exclusive remedy against, the other party for any third-party claim
          described in this Section 8.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          {" "}
          9. CONFIDENTIALITY
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>9.1 Definition of Confidential Information.</strong>{" "}
          "Confidential Information" refers to any information shared by one
          party (the “Disclosing Party”) with the other party (the “Receiving
          Party”), whether communicated verbally or in writing, that is marked
          as confidential or would reasonably be considered confidential given
          its nature and the circumstances of its disclosure. This includes, but
          is not limited to, business and marketing strategies, technological
          and technical data, product plans and designs, and business processes
          shared by the Disclosing Party. Customer's Confidential Information
          includes Customer Data, while Stomp Loyalty’s Confidential Information
          encompasses the Service and all non-public information related to the
          Service. However, each party is allowed to disclose the existence and
          terms of this Agreement confidentially to potential buyers or
          successors in the event of a reorganization, spin-off, or sale of a
          part or all of the business or assets. Confidential Information does
          not include information that (a) is publicly known without breaching
          any confidentiality obligations, (b) was already known to the
          Receiving Party before disclosure without breaching any obligations,
          (c) is received from a third party without breaching any
          confidentiality obligations, or (d) is independently developed by the
          Receiving Party without breaching any obligations.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>9.2 Protection of Confidential Information.</strong> The
          Receiving Party must protect the Disclosing Party's Confidential
          Information with the same level of care as it uses to protect its own
          similar Confidential Information (but at least reasonable care). The
          Receiving Party can only use the Confidential Information for purposes
          outlined in this Agreement. Disclosure of Confidential Information to
          the Receiving Party's Affiliates or its investors, and their
          respective officers, directors, principals, employees, attorneys, and
          accountants is permitted only to the extent necessary to fulfill the
          Agreement. If the Receiving Party needs to disclose Confidential
          Information to other persons, the recipient must sign a
          confidentiality agreement similar to this Section before any
          disclosure.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>9.3 Compelled Disclosure.</strong> If the Receiving Party is
          legally required to disclose Confidential Information, it must notify
          the Disclosing Party of the required disclosure (if legally
          permissible) and provide reasonable assistance at the Disclosing
          Party’s expense if the Disclosing Party wishes to contest the
          disclosure. If the disclosure occurs as part of a legal proceeding
          where the Disclosing Party is a party and does not contest the
          disclosure, the Disclosing Party will cover the Receiving Party's
          reasonable costs for preparing and providing secure access to the
          Confidential Information.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>9.4 Survival.</strong> The confidentiality and non-use
          obligations described in this Section will continue for five (5) years
          following the expiration or termination of this Agreement, except that
          trade secrets disclosed under this Agreement will remain confidential
          indefinitely.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight="bold"
          sx={{ marginBottom: 1 }}
        >
          {" "}
          10. GENERAL PROVISIONS
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.1 Manner of Giving Notice.</strong> Except as otherwise
          specified in this Agreement, all notices, permissions, and approvals
          must be in writing and will be considered given when: (a) personally
          delivered, (b) mailed and received on the second business day after
          posting, (c) sent by confirmed facsimile and received on the second
          business day after transmission, or (d) sent by email and received on
          the first business day after sending. Notices to You should be sent to
          the contact information provided in the Registration Form.
          Additionally, Stomp Loyalty may need to notify You and Account Users
          of important operational updates, such as downtime, through online
          notices.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.2 Modifications.</strong> Stomp Loyalty reserves the right
          to change or modify any terms or conditions in this Agreement, the
          Service, or any related policy at any time by posting the updated
          Agreement on the Stomp Loyalty website at:
          <Link href="https://www.stomployalty.com" underline="hover">
            https://www.stomployalty.com
          </Link>
          . Stomp Loyalty will make reasonable efforts to inform You of these
          changes, which may include posting an announcement on the website,
          in-product notices, or via email. Continued use of the Service after
          such changes constitutes Your acceptance. If You do not agree with the
          changes, You may cancel Your subscription by providing Stomp Loyalty
          with at least seven (7) days' written notice within thirty (30) days
          of the change notification.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.3 Co-Marketing.</strong> Except where prohibited by local
          law, you agree to participate in reasonable marketing activities to
          promote the Service and allow Stomp Loyalty to use your name and logo
          on its website and promotional materials. You also agree that Stomp
          Loyalty may identify you as a customer.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.4 Export Compliance.</strong> The Service, Stomp Loyalty
          Library, and related technologies may be subject to export laws and
          regulations in the U.S. and other jurisdictions. Each party confirms
          that it is not located in any jurisdiction where the Service, Stomp
          Loyalty Library, or Customer Data is restricted by U.S. or other
          applicable laws (a "Prohibited Jurisdiction"). You must not provide
          access to these resources to any government, entity, or individual in
          a Prohibited Jurisdiction. Each party also confirms that it is not on
          any U.S. government export restriction lists, is not from a Prohibited
          Jurisdiction, and will comply with all applicable laws regarding the
          export of technical data.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.5 Force Majeure.</strong> Neither party will be liable for
          any delay or failure in performance caused by circumstances beyond its
          reasonable control, including acts of God, natural disasters,
          terrorist activities, legal or regulatory actions, civil or military
          disturbances, internet failures, or third-party service failures.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.6 Assignment.</strong> Neither party may assign its rights
          or obligations under this Agreement without the other party's prior
          written consent (which will not be unreasonably withheld). However,
          either party may assign this Agreement in its entirety without consent
          in connection with a merger, acquisition, or sale of substantially all
          assets. This Agreement will bind and benefit the parties, their
          successors, and permitted assigns.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.7 Governing Law; Jurisdiction.</strong> The laws of the
          State of Delaware will govern this Agreement, without regard to
          conflict of law principles. The parties consent to the exclusive
          jurisdiction of the courts located in Delaware, United States.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.8 Government End Use Provisions.</strong> This Section
          applies if the Customer is a government entity. The Service, including
          related software and technology, is provided for federal government
          end use according to this Agreement. If a government agency requires
          additional rights, it must negotiate with Stomp Loyalty for acceptable
          terms, and a written addendum must be added to the agreement. The
          Service was developed at private expense.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.9 Relationship of the Parties; Non-exclusivity.</strong>{" "}
          The parties are independent contractors. This Agreement does not
          create a partnership, joint venture, agency, or employment
          relationship. Stomp Loyalty is free to provide its Service or
          technology to other parties, and the Customer is free to obtain
          similar services from other providers.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.10 Third-Party Beneficiaries.</strong> This Agreement does
          not grant any rights to third parties.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.11 Severability.</strong> If any provision of this
          Agreement is found to be illegal or unenforceable by a competent
          court, that provision will be deemed void, and the remaining
          provisions will continue in effect.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.12 Electronic Signature.</strong> Electronic signatures,
          whether digital or encrypted, will have the same effect as manual
          signatures. An electronic signature refers to any electronic, symbol,
          or process attached to or associated with a record and executed with
          the intent to sign the record.
        </Typography>

        <Typography sx={{ fontWeight: 400, marginBottom: 2 }}>
          <strong>10.13 Entire Agreement.</strong> This Agreement, including the
          Registration Form and Acceptable Use Policy, constitutes the entire
          agreement between the parties regarding its subject matter. It
          supersedes all prior discussions, proposals, or agreements. Any terms
          on purchase orders or similar documents submitted by you will not
          affect this Agreement.
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
