import React, { useState } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Collapse,
  Button,
  Divider,
  Skeleton,
} from "@mui/material";
import { StoreCard } from "../auth/firestore/getFirestoreCards";
import defaultIcon from "../../assets/defaultIcon.svg";
import StampCard from "./stampCard";
import { Link } from "react-router-dom";

interface CardPopupProps {
  card: StoreCard;
}

const CardPopup: React.FC<CardPopupProps> = ({ card }) => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const combinedRewards = [
    ...(card?.rewards || []),
    { reward: card?.reward || "", scansRequired: card?.maxScans || 0 },
  ];

  return (
    <Box
      sx={{
        padding: "0px",
        backgroundColor: "#ffecef",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
      }}
    >
      {card.titleImage && (
        <>
          {!imageLoaded && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={250}
              sx={{
                borderTopRightRadius: "4px",
                borderTopLeftRadius: "4px",
              }}
            />
          )}
          <CardMedia
            component="img"
            image={card.titleImage}
            alt={`${card.storeName} promotion`}
            onLoad={() => setImageLoaded(true)}
            sx={{
              display: imageLoaded ? "block" : "none",
              maxHeight: "300px",
              width: "100%",
              borderTopRightRadius: "4px",
              borderTopLeftRadius: "4px",
            }}
          />
        </>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <StampCard
          icon={card.cardIcon || defaultIcon}
          maxScans={card.maxScans}
          scans={card.scans}
          rewards={combinedRewards}
        />
      </Box>

      <Box sx={{ padding: "10px 16px" }}>
        <Typography variant="h4">
          <strong>{card.storeName}</strong>
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body1">
          <strong>Current Scans:</strong> {card.scans}
        </Typography>
        <Typography variant="body1">
          <strong>Scans Required for Reward:</strong> {card.maxScans}
        </Typography>
        <Typography variant="body1">
          <strong>Reward:</strong> {card.reward}
        </Typography>

        {card.disclaimer && (
          <Box
            onClick={() => setShowDisclaimer(!showDisclaimer)}
            sx={{ cursor: "pointer", marginTop: 1 }}
          >
            <Typography variant="body2" sx={{ textDecoration: "underline" }}>
              Terms and Conditions
            </Typography>
            <Collapse in={showDisclaimer}>
              <Box sx={{ paddingRight: 1, marginTop: -1 }}>
                <ul>
                  {card.disclaimer.split("\n").map((point, index) => (
                    <li key={index}>
                      <Typography variant="body2">{point}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Collapse>
          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 1 }}>
          <Link
            to={`/wallet/${card.storeURL}`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained" color="primary">
              Explore
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default CardPopup;
