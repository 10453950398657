import React, { useState } from "react";
import { Box, Typography, Popover } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

// Props for the StampCard component
interface StampCardProps {
  icon: string;
  maxScans: number;
  scans: number;
  rewards?: { reward: string; scansRequired: number }[];
}

// StampCard component
const StampCard: React.FC<StampCardProps> = ({
  icon,
  maxScans,
  scans,
  rewards = [],
}) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [rewardContent, setRewardContent] = useState<string>("");

  const itemsPerRow = maxScans === 8 ? 4 : 5; // Dynamic row layout
  const scansRequired = rewards.map((reward) => reward.scansRequired);

  // Handle popup open
  const handleStarClick = (
    event: React.MouseEvent<SVGSVGElement>,
    reward: string
  ) => {
    setAnchorEl(event.currentTarget);
    setRewardContent(reward);
  };

  // Handle popup close
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const circles = Array.from({ length: maxScans }, (_, index) => {
    const isScanned = index < scans;
    const isRewardCircle = scansRequired.includes(index + 1);
    const reward = rewards.find((r) => r.scansRequired === index + 1)?.reward;
    const borderColor = isScanned
      ? isRewardCircle
        ? "rgba(255, 223, 0, 1)" // Gold for rewards
        : "rgba(255, 0, 0, 0)" // Transparent for normal scans
      : "rgba(255, 0, 0, 0.5)"; // Light red for unscanned

    return (
      <Box
        key={index}
        sx={{
          width: 32,
          height: 32,
          display: "inline-block",
          margin: "2px",
          borderRadius: "50%",
          backgroundColor: isScanned ? "transparent" : "none",
          border: `2px solid ${borderColor}`,
          position: "relative",
        }}
      >
        {/* Scanned icon */}
        {isScanned && (
          <img
            src={icon}
            alt={`scan-${index}`}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              objectFit: "cover",
              zIndex: 1,
            }}
          />
        )}
        {/* Reward star */}
        {isRewardCircle && (
          <StarIcon
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              color: isScanned ? "transparent" : "lightgray",
              cursor: reward ? "pointer" : "default",
            }}
            onClick={(event) => reward && handleStarClick(event, reward)}
          />
        )}
      </Box>
    );
  });

  return (
    <>
      <Box
        sx={{
          width: "fit-content",
          border: "1px solid #ddd",
          borderRadius: 2,
          padding: 1,
          display: "grid",
          gridTemplateColumns: `repeat(${itemsPerRow}, auto)`,
          backgroundColor: "#F5F5F5",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        {circles}
      </Box>

      {/* Reward Popover */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ padding: 2, maxWidth: 200, textAlign: "center" }}>
          <Typography variant="body1">
            <strong>Reward:</strong> {rewardContent}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default StampCard;
