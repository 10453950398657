import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../../../firebaseConfig";

interface StoreDetails {
  storeName: string;
  titleImage: string;
  city: string;
  province: string;
  storeNumber: string;
  maxScans: number;
  storeURL: string;
  reward: string;
  socialURL: string;
  [key: string]: any;
}

export const getDynamicStoreDetails = async (
  storeURL: string
): Promise<StoreDetails | null> => {
  try {
    const storeQuery = query(
      collection(db, "stores"),
      where("storeURL", "==", storeURL)
    );
    const storeSnapshot = await getDocs(storeQuery);

    if (storeSnapshot.empty) {
      console.log("No store found with this URL:", storeURL);
      return null;
    }

    const storeData = storeSnapshot.docs[0].data() as StoreDetails;

    const updatedStoreData = {
      ...storeData,
      storeID: storeSnapshot.docs[0].id as string,
    };

    let returnStoreData = updatedStoreData;

    return returnStoreData;
  } catch (error) {
    console.error("Error fetching store details for URL:", storeURL, error);
    throw error;
  }
};
