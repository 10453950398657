"use client";

import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { z as zod } from "zod";
import { Card, CardContent, Link } from "@mui/material";

import { authClient } from "../../lib/auth/client";
import { OutlinedInput } from "@mui/material";

const schema = zod.object({
  email: zod.string().min(1, { message: "Email is required" }).email(),
});

type Values = zod.infer<typeof schema>;

const defaultValues = { email: "" } satisfies Values;

export function ResetPasswordForm(): React.JSX.Element {
  const [isPending, setIsPending] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Values>({ defaultValues, resolver: zodResolver(schema) });

  const onSubmit = React.useCallback(
    async (values: Values): Promise<void> => {
      setIsPending(true);
      setSuccess(false);

      const { error } = await authClient.resetPassword(values);

      if (error) {
        setError("root", { type: "server", message: error });
        setIsPending(false);
        return;
      }

      setSuccess(true);
      setIsPending(false);
    },
    [setError]
  );

  return (
    <Card sx={{ maxWidth: 400, margin: "0 auto", backgroundColor: "white" }}>
      <CardContent>
        <Stack spacing={4}>
          <Typography variant="h5">Reset Password</Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.email)} fullWidth>
                    <InputLabel>Email address</InputLabel>
                    <OutlinedInput
                      {...field}
                      label="Email address"
                      type="email"
                    />
                    {errors.email ? (
                      <FormHelperText>{errors.email.message}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
              {errors.root ? (
                <Alert severity="error">{errors.root.message}</Alert>
              ) : null}

              <Button
                disabled={isPending || success}
                type="submit"
                variant="contained"
              >
                Send Recovery Link
              </Button>
              {success && (
                <Alert severity="success">
                  Password reset link successfully sent to your email.
                </Alert>
              )}

              <Typography color="text.secondary" variant="body2">
                Remembered something?{" "}
                <Link
                  component={RouterLink}
                  to="/auth/sign-in"
                  underline="hover"
                  variant="subtitle2"
                >
                  Sign in
                </Link>
              </Typography>
              <Typography color="text.secondary" variant="body2">
                Don't have an account?{" "}
                <Link
                  component={RouterLink}
                  to="/auth/sign-up"
                  underline="hover"
                  variant="subtitle2"
                >
                  Sign up
                </Link>
              </Typography>
            </Stack>
          </form>
        </Stack>
      </CardContent>
    </Card>
  );
}
