import React from "react";
import { Box, Container } from "@mui/material";
import { ResetPasswordForm } from "../components/auth/mainAuth/ResetPasswordForm";

const ResetPasswordPage: React.FC = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "100%" }}>
        {" "}
        <ResetPasswordForm />
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;
